import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Nikton
			</title>
			<meta name={"description"} content={"Досвід гольфу в найкращому вигляді"} />
			<meta property={"og:title"} content={"Nikton"} />
			<meta property={"og:description"} content={"Досвід гольфу в найкращому вигляді"} />
			<meta property={"og:image"} content={"https://vortexicobels.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vortexicobels.com/img/10344957.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vortexicobels.com/img/10344957.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vortexicobels.com/img/10344957.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vortexicobels.com/img/10344957.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vortexicobels.com/img/10344957.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vortexicobels.com/img/10344957.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://vortexicobels.com/img/3.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="660px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h1"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					Про компанію
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
				Послуги гольф-клубу Nikton
				</Text>
				<Text font="--base">
				У гольф-клубі Nikton ми прагнемо забезпечити винятковий досвід для всіх наших членів і гостей. Наш широкий спектр послуг розроблений таким чином, щоб задовольнити всі ваші потреби, гарантуючи, що час, проведений з нами, буде приємним і незабутнім. Від першокласного обладнання до професійного навчання, гольф-клуб Nikton пропонує все необхідне, щоб підвищити рівень вашої гри та отримати максимальну користь від вашого візиту.
				</Text>
			</Box>
		</Section>
		<Section padding="120px 0 140px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-4">
			<Text margin="0px 0px 0px 0px" font="normal 700 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
			Найсучасніші тренувальні майданчики
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="50px 0px 0px 0px"
				md-flex-direction="column"
				sm-margin="30px 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					margin="0px 10% 0px 0px"
					padding="4px 0px 0px 0px"
					md-width="100%"
					md-margin="0px 10% 50px 0px"
					sm-padding="0 0px 0px 0px"
					sm-margin="0px 10% 35px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sansHelvetica" color="#35363a">
					Поле для гольфу: На нашому просторому полі для гольфу ви зможете розім'ятися перед раундом або відпрацювати свій замах. Обладнане кількома мішенями та доглянутим газоном, воно забезпечує реалістичні умови для тренувань.
					</Text>
					<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sansHelvetica" color="#35363a">
					Паттінг-грін: Потренуйтеся у грі на наших ретельно доглянутих майданчиках для гри на паттінг-грінах. Ці зони спроектовані таким чином, щоб відтворювати умови нашого поля, допомагаючи вам вдосконалювати свої навички.
					</Text>
					<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sansHelvetica" color="#35363a">
					Зона для чіпінгу: Відточуйте свою техніку чіпування на нашій спеціальній зоні для чіпування з бункерами та різноманітними перешкодами, що імітують умови на полі.
					</Text>
					<Text margin="0px 0px 25px 0px" font="normal 300 25px/1.5 --fontFamily-sansHelvetica" color="#35363a">
					Пріоритетний час на гольфі: Учасники користуються пріоритетним доступом до часів для гри, що гарантує вам можливість грати тоді, коли вам зручно.infused with local insights and expertise, making your adventure authentic and memorable.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://vortexicobels.com/img/4.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://vortexicobels.com/img/5.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text margin="20px 0px 0px 0px" font="normal 700 32px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
				Зв'яжіться з нами для отримання додаткової інформації
				</Text>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					І це тільки початок. Відкрийте для себе повний спектр наших пропозицій, зв'язавшись з нами сьогодні. Незалежно від того, чи хочете ви стати членом клубу, чи плануєте наступний захід, наша команда завжди готова допомогти вам. Зв'яжіться з нами, щоб дізнатися більше про те, як Nikton Golf Club може покращити ваш досвід гри в гольф.
Ми будемо раді вітати вас у гольф-клубі Nikton і допомогти вам максимально ефективно використати час, проведений у нас.

				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});